import custom from '@/modules/license-registration-service/store/custom'
/**
 * This store will be used for all modules of this component
 */
const stateVars = {
  commonObj: {
    loadList: false,
    hasDropdownLoaded: false,
    perPage: 10,
    dateFormat: 'dd/mm/YYYY',
    timeFormat: 'h:m',
    loading: false,
    listReload: false,
    feeNamesList: [],
    serviceNamesList: [],
    dynamicFormList: [],
    stepNamesList: [],
    inputOptionList: custom.inputOptionList,
    operatorList: custom.operatorList,
    serviceSteps: [],
    serviceStepForm: [],
    dynamicButtonList: [],
    seviceStepWiseButtonList: [],
    menuInfoList: [],
    alignmentList: custom.alignmentList,
    autoGenNumTypeList: []
  }
}

export default stateVars
