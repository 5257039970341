import RestApi, { commonServiceBaseUrl, licenseRegistrationServiceBaseUrl } from '../config/api_config'

export default {
  data () {
    return {
      countryList: []
    }
  },
  computed: {
    hasDropdownLoadedLicenseRegistration () {
      return this.$store.state.licenseRegistration.commonObj.hasDropdownLoaded
    }
  },
  watch: {
    hasDropdownLoadedLicenseRegistration: function (newValue) {
      if (!newValue) {
        this.loadDropdownCommonConfig()
      }
    }
  },
  created () {
    const hasDropdownLoadedLicenseRegistration = this.$store.state.licenseRegistration.hasDropdownLoaded
    if (!hasDropdownLoadedLicenseRegistration || window.performance) {
    }
    this.getCountryList()
    this.loadDropdownCommonConfig()
  },
  methods: {
    async getCountryList () {
      const result = await RestApi.getData(commonServiceBaseUrl, 'common/country-list')
      this.countryList = result.data.length > 0 ? result.data : []
    },
    loadDropdownCommonConfig () {
      RestApi.getData(licenseRegistrationServiceBaseUrl, 'common-dropdowns', null).then(response => {
        if (response.success) {
          this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', {
            hasDropdownLoaded: true,
            orgNotesList: response.data.orgNotesList,
            feeNamesList: response.data.feeNamesList,
            serviceNamesList: response.data.serviceNamesList,
            stepNamesList: response.data.stepNamesList,
            dynamicFormList: response.data.dynamicFormList,
            serviceSteps: response.data.serviceSteps,
            serviceStepForm: response.data.serviceStepForm,
            dynamicButtonList: response.data.dynamicButtonList,
            seviceStepWiseButtonList: response.data.seviceStepWiseButtonList,
            menuInfoList: response.data.menuInfoList,
            reportMenuList: response.data.reportMenuList,
            reportProcess: response.data.reportProcess,
            autoGenNumTypeList: response.data.autoGenNumTypeList,
            labList: response.data.labList,
            activeIngredientList: response.data.activeIngredientList,
            agricultureProductTypeList: response.data.agricultureProductTypeList,
            agricultureProductList: response.data.agricultureProductList,
            stepFeeList: response.data.stepFeeList,
            foreignEnlishmentList: response.data.foreignEnlishmentList,
            countryList: this.countryList,
            preconditionList: response.data.preconditionList,
            cropList: response.data.cropList,
            fertilizerName: response.data.fertilizerName,
            noticeList: response.data.noticeList,
            challanList: response.data.challanList,
            marketList: response.data.marketList
          })
          this.$store.dispatch('licenseRegistration/changeLicenseRegistrationDropdown', { value: this.$i18n.locale })
        }
      })
    }
  }
}
